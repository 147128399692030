import { WabaConversationConsumption } from '../../models/billing/sessionConsumption/WabaConversationConsumption';
import { OldConsolidatedSessionConsumption } from '../../models/billing/sessionConsumption/OldConsolidatedSessionConsumption';
import { OldDailySessionConsumption } from '../../models/billing/sessionConsumption/OldDailySessionConsumption';
import { OldMonthlySessionConsumption } from '../../models/billing/sessionConsumption/OldMonthlySessionConsumption';
import { BlipApi } from '../BlipApi';
import { METHOD, RequestStatus } from 'shared/Requests';
import { SummarySessionConsumption } from 'models/billing/sessionConsumption/SummarySessionConsumption';
import { MonthlySessionConsumption } from 'models/billing/sessionConsumption/MonthlySessionConsumption';
import { DailySessionConsumption } from 'models/billing/sessionConsumption/DailySessionConsumption';
import { ConsolidatedSessionConsumption } from 'models/billing/sessionConsumption/ConsolidatedSessionConsumption';

export default class BillingSessionConsumptionApi {
  private readonly BILLING_COMMAND_DESTINATION = 'postmaster@billing.blip.ai';
  private code = '';
  private status = '';

  public getStatus() {
    return this.status;
  }

  public getCode() {
    return this.code;
  }

  public setSuccess() {
    this.status = RequestStatus.SUCCESS;
  }

  public setErrorLog(e) {
    const error = JSON.parse(<string>e);
    const {
      status,
      reason: { code },
    } = JSON.parse(error.message);
    this.code = code;
    this.status = status;
    console.log(e);
  }

  public async getWabaConversationConsumption(tenantId: string): Promise<WabaConversationConsumption[]> {
    const uri = `/tenants/${tenantId}/wabas`;

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };
    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return items;
    } catch (e) {
      this.setErrorLog(e);
      return new Array<WabaConversationConsumption>();
    }
  }

  public async getOldConsolidatedSessionConsumption(
    tenantId: string,
    month: string,
    conversationType: string,
    wabaId: string,
  ): Promise<OldConsolidatedSessionConsumption> {
    const uri = `/tenants/${tenantId}/whatsapp-sessions/${conversationType}/${wabaId}/total?month=${month}`;

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };
    if (!wabaId) {
      return new OldConsolidatedSessionConsumption(null);
    }

    try {
      const { response } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return new OldConsolidatedSessionConsumption(response);
    } catch (e) {
      this.setErrorLog(e);
      return new OldConsolidatedSessionConsumption(null);
    }
  }

  public async getConsolidatedSessionConsumption(
    tenantId: string,
    month: string,
    wabaId: string,
  ): Promise<ConsolidatedSessionConsumption> {
    const uri = `/tenants/${tenantId}/whatsapp-sessions/${wabaId}/by-category?month=${month}`;

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };

    if (!wabaId) {
      return new ConsolidatedSessionConsumption(null);
    }

    try {
      const { response } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return new ConsolidatedSessionConsumption(response);
    } catch (e) {
      this.setErrorLog(e);
      return new ConsolidatedSessionConsumption(null);
    }
  }

  public async getSummarySessionConsumption(tenantId: string, month: string): Promise<SummarySessionConsumption> {
    const uri = `/tenants/${tenantId}/whatsapp-sessions/summary?month=${month}`;

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };

    try {
      const { response } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return new SummarySessionConsumption(response);
    } catch (e) {
      this.setErrorLog(e);
      return new SummarySessionConsumption(null);
    }
  }

  public async getOldMonthlySessionConsumption(
    tenantId: string,
    month: string,
    registersToTake: number,
    conversationType: string,
    wabaId: string,
  ): Promise<OldMonthlySessionConsumption[] | null> {
    let uri = `/tenants/${tenantId}/whatsapp-sessions/${conversationType}/${wabaId}/monthly/by-bot?month=${month}`;

    if (registersToTake > 0) {
      uri = uri + `&$take=${registersToTake}`;
    }

    if (!wabaId) {
      return new Array<OldMonthlySessionConsumption>();
    }

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return items.map((item) => new OldMonthlySessionConsumption(item));
    } catch (e) {
      this.setErrorLog(e);
      return new Array<OldMonthlySessionConsumption>();
    }
  }

  public async getMonthlySessionConsumption(
    tenantId: string,
    month: string,
    registersToTake: number,
    wabaId: string,
  ): Promise<MonthlySessionConsumption[] | null> {
    let uri = `/tenants/${tenantId}/whatsapp-sessions/monthly/${wabaId}/by-phone-number?month=${month}`;
    if (registersToTake > 0) {
      uri = uri + `&$take=${registersToTake}`;
    }

    if (!wabaId) {
      return new Array<MonthlySessionConsumption>();
    }

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return items.map((item) => new MonthlySessionConsumption(item));
    } catch (e) {
      this.setErrorLog(e);
      return new Array<MonthlySessionConsumption>();
    }
  }

  public async getOldDailySessionConsumption(
    tenantId: string,
    month: string,
    registersToTake: number,
    conversationType: string,
    wabaId: string,
  ): Promise<OldDailySessionConsumption[] | null> {
    let uri = `/tenants/${tenantId}/whatsapp-sessions/${conversationType}/${wabaId}/daily?month=${month}`;

    if (registersToTake > 0) {
      uri = uri + `&$take=${registersToTake}`;
    }

    if (!wabaId) {
      return new Array<OldDailySessionConsumption>();
    }

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return items.map((item) => new OldDailySessionConsumption(item));
    } catch (e) {
      this.setErrorLog(e);
      return new Array<OldDailySessionConsumption>();
    }
  }

  public async getDailySessionConsumption(
    tenantId: string,
    month: string,
    registersToTake: number,
    wabaId: string,
  ): Promise<DailySessionConsumption[] | null> {
    let uri = `/tenants/${tenantId}/whatsapp-sessions/${wabaId}/daily?month=${month}`;

    if (registersToTake > 0) {
      uri = uri + `&$take=${registersToTake}`;
    }

    if (!wabaId) {
      return new Array<DailySessionConsumption>();
    }

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return items.map((item) => new DailySessionConsumption(item));
    } catch (e) {
      this.setErrorLog(e);
      return new Array<DailySessionConsumption>();
    }
  }
}
