import { BlipApi } from './BlipApi';

const BILLING_POSTMASTER = 'postmaster@billing.msging.net';

export const getSubscriptionPlanMetrics = async (tenantId) => {
  try {
    const subscriptions = await BlipApi.sendCommand({
      to: BILLING_POSTMASTER,
      method: 'get',
      uri: `/tenants/${tenantId}/subscription/plan/metrics`,
    });
    return subscriptions.response.items;
  } catch (e) {
    console.error(e);
    return;
  }
};

export const getSubscriptionPlan = async (tenantId) => {
  try {
    const {response } = await BlipApi.sendCommand({
      to: BILLING_POSTMASTER,
      method: 'get',
      uri: `/tenants/${tenantId}/subscription/plan`,
    });
    return response.name;
  } catch (e) {
    console.error(e);
    return;
  }
};

export const BillingApi = {
  getSubscriptionPlan,
};
