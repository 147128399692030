import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ContactHome from '../views/ContactHome';
import { Home } from '../views/Home';
import { Agent } from '../views/Agent';
import { billingRoutes, BillingRoutes } from './BillingRoutes';
import { paths } from './paths';
import { BillingMetrics } from '../shared/BillingMetrics';
import { accessPermission, canUserWrite } from '../services/PermissionService';
import { useTenant } from '../contexts/TenantContext';
import { PrivateRoute } from '../components/PrivateRoute';
import {
  CONTRACT_MEMBERS,
  CONTRACT_REVENUE_CLICK,
  DAILY_ACTIVE_USERS_CLICK,
  ORGANIZATION_AGENTS,
  SESSION_CONSUMPTION_CLICK,
  AGENT_CONSUMPTION_CLICK,
  REPORT_OVERVIEW_CONSUMPTION_OPEN,
  ORGANIZATION_COPILOT_MENU,
  ORGANIZATION_CALLS_MENU,
} from '../shared/SegmentEvents';
import {
  DAILY_ACTIVE_USERS_CONSUMPTION,
  SESSION_CONSUMPTION,
  AGENT_CONSUMPTION,
  DATA_CONSUMPTION,
  TENANT_CONSUMPTION,
  AI_SETTINGS_COPILOT,
  CALLS_SETTINGS,
} from '../shared/FeatureToggleKeys';
import Copilot from '../views/Copilot';
import { RouteOptions } from '../models/shared/RouteOptions';
import Calls from '../views/Calls';

export const generalSettingsOptions: RouteOptions[] = [
  {
    group: 'generalSettings',
    option: 'members',
    icon: 'avatar-user',
    accessPermission: accessPermission.members,
    segmentEvent: CONTRACT_MEMBERS,
    path: paths.members,
  },
  {
    group: 'generalSettings',
    option: 'agent',
    icon: 'agent',
    accessPermission: accessPermission.billing,
    segmentEvent: ORGANIZATION_AGENTS,
    metrics: BillingMetrics.agent,
    path: paths.agent,
  },
];

export const featureManagementOptions: RouteOptions[] = [
  {
    group: 'featureManagement',
    option: 'blipCopilot',
    icon: 'robot',
    featureToggle: AI_SETTINGS_COPILOT,
    accessPermission: accessPermission.members,
    segmentEvent: ORGANIZATION_COPILOT_MENU,
    path: paths.copilot,
    additionalCheck: canUserWrite,
  },
  {
    group: 'featureManagement',
    option: 'blipCalls',
    icon: 'robot',
    featureToggle: CALLS_SETTINGS,
    accessPermission: accessPermission.members,
    segmentEvent: ORGANIZATION_CALLS_MENU,
    path: paths.calls,
    additionalCheck: canUserWrite,
  },
];

export const usageDataOptions: RouteOptions[] = [
  {
    group: 'usageData',
    option: 'contractRevenue',
    icon: 'file-txt-1',
    featureToggle: TENANT_CONSUMPTION,
    accessPermission: accessPermission.billing,
    segmentEvent: CONTRACT_REVENUE_CLICK,
    metrics: [BillingMetrics.monthlyActiveUser, BillingMetrics.monthlyEngagedUser, BillingMetrics.exchangedMessage],
    path: billingRoutes.revenue,
  },
  {
    group: 'usageData',
    option: 'dailyActiveUsers',
    icon: 'user-engaged',
    featureToggle: DAILY_ACTIVE_USERS_CONSUMPTION,
    accessPermission: accessPermission.billing,
    segmentEvent: DAILY_ACTIVE_USERS_CLICK,
    metrics: [BillingMetrics.dailyActiveUser],
    path: billingRoutes.dailyActiveUsers,
  },
  {
    group: 'usageData',
    option: 'sessionConsumption',
    icon: 'message-talk',
    featureToggle: SESSION_CONSUMPTION,
    accessPermission: accessPermission.billing,
    segmentEvent: SESSION_CONSUMPTION_CLICK,
    metrics: [BillingMetrics.session],
    path: billingRoutes.oldSessionConsumption,
  },
  {
    group: 'usageData',
    option: 'agentConsumption',
    icon: 'agent',
    featureToggle: AGENT_CONSUMPTION,
    accessPermission: accessPermission.billing,
    segmentEvent: AGENT_CONSUMPTION_CLICK,
    metrics: [BillingMetrics.agent],
    path: billingRoutes.oldAgentConsumption,
  },
  {
    group: 'usageData',
    option: 'dataConsumption',
    icon: 'monitoring',
    featureToggle: DATA_CONSUMPTION,
    accessPermission: accessPermission.billing,
    segmentEvent: REPORT_OVERVIEW_CONSUMPTION_OPEN,
    metrics: Object.values(BillingMetrics).map<BillingMetrics>((metric) => metric as BillingMetrics),
    path: billingRoutes.dataConsumption,
  },
];

const Routes = () => {
  const {
    tenant: { subscription },
  } = useTenant();

  const hasEnabledMetric = (metricId: BillingMetrics): boolean =>
    subscription.some((item) => item.metricId === metricId);

  return (
    <Switch>
      <Route path={paths.home} exact component={ContactHome} />
      <Route path={paths.members} exact component={Home} />
      <Route path={paths.copilot} exact component={Copilot} />
      <Route path={paths.calls} exact component={Calls} />
      <PrivateRoute
        path={paths.agent}
        exact
        component={Agent}
        hasPermission={() => hasEnabledMetric(BillingMetrics.agent)}
      />
      <BillingRoutes />
      <Redirect to={paths.home} />
    </Switch>
  );
};

export { Routes };
