import { DailyActiveUsersMonthlyUsage } from '../models/dailyActiveUsers/DailyActiveUsersMonthlyUsage';
import { DailyActiveUserUsage } from '../models/dailyActiveUsers/DailyActiveUserUsage';
import { CompiledDailyActiveUserConsumption } from '../models/dailyActiveUsers/CompiledDailyActiveUserConsumption';
import { BlipApi } from './BlipApi';
import { getFirstDayOfSpecifiedMonth, getLastDayOfSpecifiedMonth } from 'utils/date';

export default class BillingDailyActiveUsersServiceApi {
  private readonly BILLING_COMMAND_DESTINATION = 'postmaster@billing.blip.ai';

  public async getCompiledDailyActiveUserConsumption(
    tenantId: string,
    month: string,
  ): Promise<CompiledDailyActiveUserConsumption | null> {
    const uri = `/tenants/${tenantId}/daily-active-users/total?month=${month}`;

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: 'get',
      uri: uri,
    };

    const consolidatedDailyActiveUserConsumption = {
      metric: null,
      total: 0,
      planUsageRate: 0,
      exceededPlan: 0,
      planUsage: 0,
      availablePlan: 0,
      startDateReference: getFirstDayOfSpecifiedMonth(new Date()),
      endDateReference: getLastDayOfSpecifiedMonth(new Date()),
    };

    try {
      const { response } = await BlipApi.sendCommand(command);
      return new CompiledDailyActiveUserConsumption(response);
    } catch (e) {
      return consolidatedDailyActiveUserConsumption;
    }
  }

  public async getDailyActiveUserMonthlyUsage(
    tenantId: string,
    month: string,
    registersToTake: number,
  ): Promise<DailyActiveUsersMonthlyUsage[] | null> {
    let uri = `/tenants/${tenantId}/daily-active-users/monthly/by-bot?month=${month}`;

    if (registersToTake > 0) {
      uri = uri + `&$take=${registersToTake}`;
    }
    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: 'get',
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      return items.map((item) => new DailyActiveUsersMonthlyUsage(item));
    } catch (e) {
      return new Array<DailyActiveUsersMonthlyUsage>();
    }
  }

  public async getDailyActiveUserUsage(
    tenantId: string,
    month: string,
    registersToTake: number,
  ): Promise<DailyActiveUserUsage[] | null> {
    let uri = `/tenants/${tenantId}/daily-active-users/daily?month=${month}`;

    if (registersToTake > 0) {
      uri = uri + `&$take=${registersToTake}`;
    }
    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: 'get',
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      return items.map((item) => new DailyActiveUserUsage(item));
    } catch (e) {
      return new Array<DailyActiveUserUsage>();
    }
  }
}
