import React, { useEffect, useMemo, useState } from 'react';
import { useCommon } from '../../contexts/CommonContext';
import { localization } from './localization';
import { useContentLocalizer } from '../../hooks/useContentLocalizer';
import { useTenant } from 'contexts/TenantContext';
import { Card, Container, Footer, SubTitle } from './styles';
import { BdsButton, BdsCheckbox, BdsGrid, BdsPagination, BdsSelect, BdsTypo } from 'blip-ds/dist/blip-ds-react';
import { ApplicationApi } from 'api/ApplicationApi';
import { getConfiguration } from 'api/DeskConfigurationApi';
import { applicationDomain } from 'shared/Domains';
import { BlipSearch } from '../../components/BlipSearchDs';
import { setConfiguration } from '../../api/DeskConfigurationApi';
import { showToast } from '../../api/CommonApi';
import ListBotCopilot from './ListBotCopilot';
import ListEmpty from './ListEmpty';
import { createTrack } from 'api/SegmentApi';
import { ORGANIZATION_COPILOT_ENABLED } from '../../shared/SegmentEvents';

function Copilot() {
  const {
    setHeaderContent,
    common: {
      loggedUser: { localNode },
      config,
    },
  } = useCommon();
  const {
    tenant: { info },
  } = useTenant();
  const [botsEnabled, setBotsEnabled] = useState(0);
  const [botsDisabled, setBotsDisabled] = useState(0);
  const [configBots, setConfigBots] = useState([]);
  const [botsFiltered, setBotsFiltered] = useState([]);
  const [showButtonEnabledAll, setShowButtonEnabledAll] = useState(false);
  const [showButtonDisabledAll, setShowButtonDisabledAll] = useState(false);
  const [selectAllBots, setSelectAllBots] = useState(false);
  const [amountSelectedBots, setAmountSelectedBots] = useState(5);
  const [selectPerPage, setSelectPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [pages, setPages] = useState(1);
  const [search, setSearch] = useState({ bot: '', configuration: '' });
  const translate = useContentLocalizer(localization);
  const { MSGING_DOMAIN } = config;
  const optionsPerPage = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 25, value: 25 },
    { label: 50, value: 50 },
  ];
  const optionsFilterBots = [
    { label: translate.filter.allBots , value: '' },
    { label: translate.filter.onlyEnabled, value: 'true' },
    { label: translate.filter.onlyDisabled , value: 'false' },
  ];

  const getBots = async () => {
    try {
      const { response: owners } = await ApplicationApi.sendApplicationRequest('getApplicationsByTenant', info.id);
      const configBots = await getFlagConfiguration(owners);
      setTotalRows(configBots.length);
      return configBots;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getFlagConfiguration = async (owners = []) => {
    const updatedOwners = await owners.map(async (ownerIdentity) => {
      if (!ownerIdentity.hasPermission) {
        return { ...ownerIdentity, status: false, selected: false };
      }
      try {
        const { items } = await getConfiguration({
          owner: `${ownerIdentity.id}@${MSGING_DOMAIN}`,
          caller: applicationDomain.desk,
        });
        const copilotConfig = items.find((item) => item.name === 'TenantBotBlipCopilotEnabled');
        const status = copilotConfig ? copilotConfig.value === 'True' : false;
        return { ...ownerIdentity, status };
      } catch (error) {
        console.error(error);
        return { ...ownerIdentity, status: false, selected: false };
      }
    });
    return await Promise.all(updatedOwners);
  };
  const searchBots = ({bot, configuration}) => {
    const botNormalized = normalize(bot);
    return bot.length > 0 || configuration.length > 0
    ? configBots.filter(({ name, status, hasPermission }) => {
        const nameNormalized = normalize(name);
        const checkedStatus = configuration.toString() === status.toString() && hasPermission;
        const checkedBot = nameNormalized.includes(botNormalized);

        if (bot.length > 0 && configuration.length > 0) {
          return checkedBot && checkedStatus;
        }

        if (bot.length > 0) {
          return checkedBot;
        }

        if (configuration.length > 0) {
          return checkedStatus;
        }
      })
    : configBots;
  };

  const filterBots = async ({ bot, configuration }) => {
    const bots = searchBots({bot, configuration});
    setTotalRows(bots.length);
    const amountSelected = bots.filter((bot) => bot.selected).length;
    const buttonDisabled = bots.some(({ selected, status }) => selected && status);
    const buttonEnabled = bots.some(({ selected, status }) => selected && !status);
    setAmountSelectedBots(amountSelected);
    setShowButtonDisabledAll(buttonDisabled);
    setShowButtonEnabledAll(buttonEnabled);
    setPages(Math.ceil(bots.length / selectPerPage));
    setBotsFiltered(bots.slice((page - 1) * selectPerPage, selectPerPage * page));
  };

  const normalize = (value) => {
    return value
      .toLowerCase()
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  };

  const changeConfigCopilot = async (event) => {
    const { name: id, checked: TenantBotBlipCopilotEnabled } = event.target;
    const { status } = configBots.find((bot) => bot.id === id);
    if (TenantBotBlipCopilotEnabled === status) {
      return;
    }
    try {
      const configuration = { TenantBotBlipCopilotEnabled };
      await setConfiguration({
        owner: `${id}@${MSGING_DOMAIN}`,
        caller: applicationDomain.desk,
        configuration,
        pp: localNode,
      });
      if (TenantBotBlipCopilotEnabled) {
        createTrack(info, ORGANIZATION_COPILOT_ENABLED, {});
      }
      showToast({
        type: 'success',
        message: translate.formatString(
          translate.enabledCopilot.success,
          TenantBotBlipCopilotEnabled ? translate.detailsBots.enabled : translate.detailsBots.disabled,
        ),
      });
      setConfigBots((status) => {
        const newState = status.map((bot) => {
          if (bot.id === id) {
            return {
              ...bot,
              status: TenantBotBlipCopilotEnabled,
              selected: false,
            };
          }
          return bot;
        });
        return newState;
      });
    } catch (error) {
      showToast({
        type: 'danger',
        message: translate.enabledCopilot.error,
      });
      console.error(error);
    }
  };

  const handleToggleFilter = () => {
    const filter = search.configuration !== 'true' ? 'true' : 'false';
    setSearch({ bot: search.bot, configuration: filter });
    filterBots({ bot: search.bot, configuration: filter });
  };

  const searchBotCopilot = (botName, status) => {
    setSearch({ bot: botName, configuration: status });

    if (page > 1) {
      setPage(1);
      return;
    }

    filterBots({ bot: botName, configuration: status });
  };

  const changeAllCopilotBots = async (event) => {
    const botId = [];
    const configuration = { TenantBotBlipCopilotEnabled: event };

    if (event) {
      createTrack(info, ORGANIZATION_COPILOT_ENABLED, {});
    }
    const bots = searchBots({bot: search.bot, configuration: search.configuration});
    await Promise.all(
      configBots.map(async (bot) => {
        const addedFilter = bots.some(({ id }) => id === bot.id && bot.hasPermission && bot.selected);  
        if (addedFilter) {
          try {
            await setConfiguration({
              owner: `${bot.id}@${MSGING_DOMAIN}`,
              caller: applicationDomain.desk,
              configuration,
              pp: localNode,
            });
            bot.status = event;
            botId.push(bot.id);
          } catch (error) {
            console.error(error);
          }
        }
        return { bot };
      }),
    );
    setConfigBots((status) => {
      return status.map((bot) => {
        if (botId.includes(bot.id)) {
          return { ...bot, status: event, selected: false };
        }
        return { ...bot, selected: false };
      });
    });
    setSelectAllBots(false);
    showToast({
      type: 'success',
      message: translate.formatString(
        translate.enabledCopilot.success,
        event ? translate.detailsBots.enabled : translate.detailsBots.disabled,
      ),
    });
  };

  useEffect(() => {
    setHeaderContent({
      redirect: '/',
      text: translate.header.titleRedirect,
    });

    return () => setHeaderContent({ redirect: null });
  }, []);

  useMemo(async () => {
    const deskConfig = await getBots();
    setConfigBots(deskConfig);
  }, []);

  useMemo(() => {
    if (configBots.length > 0) {
      filterBots(search);
    const botsCopilotEnabled = configBots.filter((bot) => bot.status).length;
    const botsCopilotDisabled = configBots.filter((bot) => !bot.status).length;
    setBotsEnabled(botsCopilotEnabled);
    setBotsDisabled(botsCopilotDisabled);
    }
  }, [configBots, selectPerPage, page]);

  const changeSelectBot = (event) => {
    const { checked, name } = event.target;
    setConfigBots((status) => {
      const newState = status.map((bot) => {
        if (bot.id === name) {
          return { ...bot, selected: checked };
        }
        return bot;
      });
      return newState;
    });
  };

  const changeSelectAllBots = (event) => {
    const selected = event.target.checked;
    setSelectAllBots(selected);
    setConfigBots((status) => {
      const newState = status.map((bot) => {
        if (bot.hasPermission) {
          return { ...bot, selected };
        }
        return bot;
      });
      return newState;
    });
  };

  const changePage = (event) => {
    const { detail: pageValue } = event;
    setPage(pageValue);
  };

  const pagesText = useMemo(() => {
    const startResult = totalRows <= 0 ? totalRows : selectPerPage * page - selectPerPage + 1;
    const endResult = selectPerPage * page > totalRows ? totalRows : selectPerPage * page;
    return translate.formatString(translate.page.result, startResult, endResult, totalRows);
  }, [page, selectPerPage, totalRows]);

  return (
    <Container>
      <span className="mb4">
        <BdsTypo variant="fs-24" tag="h1" bold="semi-bold" margin={false}>
          {translate.header.title}
        </BdsTypo>
      </span>
      <SubTitle className="mt5">
        <BdsTypo variant="fs-12" tag="h5" margin={false}>
          {translate.header.subTitle}
        </BdsTypo>
      </SubTitle>
      <BdsGrid gap="2">
        <Card>
          <div className="mb3">
            <BdsTypo bold="bold" variant="fs-14">
              {translate.summaryBots.enabled}
            </BdsTypo>
          </div>
          <BdsTypo bold="bold" variant="fs-24" margin={false}>
            {botsEnabled}
          </BdsTypo>
        </Card>
        <Card>
          <div className="mb3">
            <BdsTypo bold="bold" variant="fs-14">
              {translate.summaryBots.disabled}
            </BdsTypo>
          </div>
          <BdsTypo bold="bold" variant="fs-24" margin={false}>
            {botsDisabled}
          </BdsTypo>
        </Card>
      </BdsGrid>
      <div className="flex row mt3 w-100 items-center">
        <div className="w-30">
          <BlipSearch
            onChange={(botName) => searchBotCopilot(botName, search.configuration)}
            search={search.bot}
            debounce={500}
          />
        </div>
        <div className="w-70 flex justify-end row">
          <BdsSelect
            label="Filtrar por"
            className="mr2"
            value={search.configuration}
            onBdsChange={({ detail }) => searchBotCopilot(search.bot, detail.value)}
            options={optionsFilterBots}
          />
          <BdsSelect
            label="Resultados"
            onBdsChange={({ detail }) => setSelectPerPage(detail.value)}
            options={optionsPerPage}
            value={selectPerPage}
          />
        </div>
      </div>
      {totalRows > 0 && (
        <div className="flex row items-center mt4 mb3 w-100 justify-between">
          <div className="flex row items-center">
            <BdsTypo variant="fs-16" margin={false}>
              {translate.selectionBots.selectAll}
            </BdsTypo>
            <BdsCheckbox checked={selectAllBots} className="ml2" onBdsChange={changeSelectAllBots} />
          </div>
          {(showButtonEnabledAll || showButtonDisabledAll) && (
            <div className="flex row items-center">
              <BdsTypo variant="fs-16" margin={false}>
                {translate.formatString(translate.selectionBots.selected, amountSelectedBots)}
              </BdsTypo>
              {showButtonEnabledAll && (
                <BdsButton className="ml2" variant="primary" size="short" onClick={() => changeAllCopilotBots(true)}>
                  {translate.selectionBots.enable}
                </BdsButton>
              )}
              {showButtonDisabledAll && (
                <BdsButton className="ml2" variant="ghost" size="short" onClick={() => changeAllCopilotBots(false)}>
                  {translate.selectionBots.disable}
                </BdsButton>
              )}
            </div>
          )}
        </div>
      )}
      <ListBotCopilot
        bots={botsFiltered}
        translate={translate.detailsBots}
        onChangeStatus={changeConfigCopilot}
        onChangeSelectBot={changeSelectBot}
      />
      {botsFiltered.length === 0 && search.configuration !== '' && (
        <ListEmpty translate={translate.listEmpty} status={search.configuration} onChange={handleToggleFilter}/>
      )}
      {totalRows > 0 && (
        <Footer>
          <BdsTypo variant="fs-14" margin={false}>
            {pagesText}
          </BdsTypo>
          <BdsPagination pages={pages} onBdsPaginationChange={changePage} options-position="top" />
        </Footer>
      )}
    </Container>
  );
}

export default Copilot;
