import { MonthlyAgentConsumption } from 'models/agentConsumption/MonthlyAgentConsumption';
import { ConsolidatedAgentConsumption } from '../models/agentConsumption/ConsolidatedAgentConsumption';
import { getFirstDayOfSpecifiedMonth, getLastDayOfSpecifiedMonth } from '../utils/date';
import { BlipApi } from './BlipApi';

export default class BillingAgentConsumptionApi {
  private readonly BILLING_COMMAND_DESTINATION = 'postmaster@billing.blip.ai';

  public async getConsolidatedAgentConsumption(
    tenantId: string,
    month: string,
  ): Promise<ConsolidatedAgentConsumption | null> {
    const uri = `/tenants/${tenantId}/agents/total?month=${month}`;

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: 'get',
      uri: uri,
    };

    const consolidatedAgentConsumption = {
      metric: null,
      total: 0,
      planUsageRate: 0,
      exceededPlan: 0,
      planUsage: 0,
      startDateReference: getFirstDayOfSpecifiedMonth(new Date()),
      endDateReference: getLastDayOfSpecifiedMonth(new Date()),
    };

    try {
      const { response } = await BlipApi.sendCommand(command);
      return new ConsolidatedAgentConsumption(response);
    } catch (e) {
      return consolidatedAgentConsumption;
    }
  }
  public async getMonthlyAgentConsumption(
    tenantId: string,
    month: string,
    registersToTake: number,
  ): Promise<MonthlyAgentConsumption[] | null> {
    let uri = `/tenants/${tenantId}/agents/monthly/by-agent?month=${month}`;

    uri = uri + `&$take=${registersToTake}`;

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: 'get',
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      return items.map((item) => new MonthlyAgentConsumption(item));
    } catch (e) {
      return new Array<MonthlyAgentConsumption>();
    }
  }
}
