export const localization = {
  pt: {
    generalSettings: {
      title: 'Configurações gerais',
      info: 'Visualize e configure todas as informações relacionadas ao seu contrato.',
      members: {
        title: 'Membros',
        description: 'Adicione e exclua membros do contrato',
      },
      agent: {
        title: 'Atendentes',
        description: 'Adicione atendentes para seus bots no Desk',
      },
    },
    featureManagement: {
      title: 'Gerenciamento de funcionalidades',
      info: 'Gerencie as funcionalidades disponíveis para seus bots.',
      blipCopilot: {
        title: 'Blip Copilot',
        description: 'Gerencie os bots que terão acesso ao recurso',
        new: 'Novo',
      },
      blipCalls: {
        title: 'Blip Calls',
        description: 'Gerencie os bots que terão acesso ao recurso de ligações',
        new: 'Novo',
      },
    },
    usageData: {
      title: 'Acompanhamento do plano',
      info: 'Consulte os dados de consumo relacionados ao seu contrato ou chatbot.',
      notAvaiable: 'Os dados de consumo não estão disponíveis para este contrato.',
      contractRevenue: {
        title: 'Dados de Consumo',
        description: 'Visualize os dados de consumo do contrato',
      },
      dailyActiveUsers: {
        title: 'Consumo de usuários ativos diários',
        description: 'Visualize os dados de consumo de usuários ativos diários',
      },
      sessionConsumption: {
        title: 'Conversas',
        description: 'Visualize os dados de consumo de conversas',
      },
      agentConsumption: {
        title: 'Atendentes',
        description: 'Veja os dados de consumo de atendentes acionados',
      },
      dataConsumption: {
        title: 'Relatório de consumo',
        description: 'Acompanhe o consumo do seu plano',
      },
    },
  },
  en: {
    generalSettings: {
      title: 'General settings',
      info: 'View and set the general info about your contract.',
      members: {
        title: 'Members',
        description: 'Add or delete members of this contract',
      },
      agent: {
        title: 'Agent',
        description: 'Add agents to your bots on the desk',
      },
    },
    featureManagement: {
      title: 'Feature Management',
      info: 'Manage the available features for your bots.',
      blipCopilot: {
        title: 'Blip Copilot',
        description: 'Manage the bots that will have access to the feature',
        new: 'New',
      },
      blipCalls: {
        title: 'Blip Calls',
        description: 'Manage the bots that will have access to the calls feature',
        new: 'New',
      },
    },
    usageData: {
      title: 'Tracking your plan',
      info: 'Check your contract or chatbot usage information.',
      notAvaiable: 'Usage data is not available for this contract.',
      contractRevenue: {
        title: 'Usage Data',
        description: 'Usage data related to your contract',
      },
      dailyActiveUsers: {
        title: 'Daily active user Consumption',
        description: 'View Daily active user consumption data',
      },
      sessionConsumption: {
        title: 'Conversation',
        description: 'View conversation consumption data',
      },
      agentConsumption: {
        title: 'Agents',
        description: 'Check out the consumption data of activated agents',
      },
      dataConsumption: {
        title: 'Usage report',
        description: 'Track your current plan usage',
      },
    },
  },
  es: {
    generalSettings: {
      title: 'Configuración general',
      info: 'Vea y configure toda la información relacionada con su contrato.',
      members: {
        title: 'Miembros',
        description: 'Agregar y eliminar miembros del contrato',
      },
      agent: {
        title: 'Asistentes',
        description: 'Agregar asistentes a sus bots en el Desk',
      },
    },
    featureManagement: {
      title: 'Gestión de funciones',
      info: 'Administra las funciones disponibles para tus bots.',
      blipCopilot: {
        title: 'Blip Copilot',
        description: 'Administra los bots que tendrán acceso a la función',
        new: 'Nuevo',
      },
      blipCalls: {
        title: 'Blip Calls',
        description: 'Administra los bots que tendrán acceso a la función de llamadas',
        new: 'Nuevo',
      },
    },
    usageData: {
      title: 'Seguimiento de tu plan',
      info: 'Consulte datos de consumo relacionados con su contrato o chatbot.',
      notAvaiable: 'Los datos de consumo no están disponibles para este contrato.',
      contractRevenue: {
        title: 'Datos de Consumo',
        description: 'Ver datos de consumo de contrato',
      },
      dailyActiveUsers: {
        title: 'Consumo diario de usuarios activos',
        description: 'Ver datos de consumo diario de usuarios activos',
      },
      sessionConsumption: {
        title: 'Conversaciones',
        description: 'Ver datos de consumo de conversaciones',
      },
      agentConsumption: {
        title: 'Asistentes',
        description: 'Ver los datos de consumo de asistentes activados',
      },
      dataConsumption: {
        title: 'Reporte de consumo',
        description: 'Consulta el consumo de tu plan',
      },
    },
  },
};
