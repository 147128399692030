import React, { FC, useContext, useEffect, useState } from 'react';
import { BdsBanner, BdsPaper, BdsSelect } from 'blip-ds/dist/blip-ds-react';
import { BlipTable } from '../../../../components/BlipTable';
import SessionConsumptionContext from '../../../../contexts/SessionConsumptionContext';
import { getMonthTwoDigits, toUTCDateString } from '../../../../utils/date';
import { PaperHeaderComponent } from '../../components/Metrics/PaperHeader/PaperHeaderComponent';
import { PageHeaderComponent } from '../../components/Metrics/PageHeader/PageHeaderComponent';
import { trackEvent } from '../../../../api/Billing/Tracking';
import { sortByProperty } from '../../../../utils/array';
import '../../../../typings/blip-ds.d';
import { Link } from 'react-router-dom';
import {
  SessionConsumptionPaper,
  SessionConsumptionRow,
  SessionConsumptionUsageStatementContent,
  SessionConsumptionHeaderFilter,
} from '../styles';
import {
  action,
  origin,
  REPORT_PERIOD_SELECT,
  REPORT_SESSION_CONSUMPTION_CONVERSATION_SELECT,
  REPORT_SESSION_CONSUMPTION_PER_DATE_BUSINESSINITIATED_TAB_CLICK,
  REPORT_SESSION_CONSUMPTION_PER_DATE_MOREINFO_CLICK,
  REPORT_SESSION_CONSUMPTION_PER_DATE_OPEN,
  REPORT_SESSION_CONSUMPTION_PER_DATE_USERINITIATED_TAB_CLICK,
  REPORT_SESSION_CONSUMPTION_WABAID_SELECT,
} from '../../../../shared/SegmentEvents';

export interface SessionConsumptionDailyUsageProps {
  registersToTake: number;
  tableBodyHeight: string;
}

const SessionConsumptionDailyUsageComponent: FC<SessionConsumptionDailyUsageProps> = ({
  registersToTake = -1,
  tableBodyHeight = '200px',
}) => {
  const {
    dateToSearch,
    content,
    tenant,
    loggedUser,
    trackingProps,
    getPlanStatus,
    sortSessionConsumptionDailyUsage,
    billingSessionConsumptionApi,
    oldSessionConsumptionDailyUsage,
    setOldSessionConsumptionDailyUsage,
    periodOptions,
    setDateToSearch,
    conversationTypeFilter,
    conversationWabaFilter,
    conversationTypeFilterOptions,
    conversationWabaFilterOptions,
    setConversationTypeFilter,
    setConversationWabaFilter,
    isWabaloading,
    WHATSAPP_BP_PRICING_CHANGES_URL,
    WHATSAPP_BP_PRICING_CHANGES_START_DATE,
    showSessionNewPricing,
    setShowSessionNewPricing,
  } = useContext(SessionConsumptionContext);
  const [isLoading, setLoading] = useState(false);
  const [businessInitiatedDailyUsage, setBusinessInitiatedDailyUsage] = useState([] as any);
  const [userInitiatedDailyUsage, setUserInitiatedDailyUsage] = useState([] as any);
  const isDetailedView = registersToTake === -1;

  const businessInitiatedTable = [
    { label: content.sessionConsumptionByDate.columnDateReference, key: 'dateReference' },
    { label: content.sessionConsumptionByDate.businessInitiated.columnUtility, key: 'utility' },
    { label: content.sessionConsumptionByDate.businessInitiated.columnAuthentication, key: 'authentication' },
    { label: content.sessionConsumptionByDate.businessInitiated.columnMarketing, key: 'marketing' },
    { label: content.sessionConsumptionByDate.columnTotal, key: 'total' },
  ];

  const userInitiatedTable = [
    { label: content.sessionConsumptionByDate.columnDateReference, key: 'dateReference' },
    { label: content.sessionConsumptionByDate.userInitiated.service, key: 'service' },
    { label: content.sessionConsumptionByDate.userInitiated.free, key: 'free' },
    { label: content.sessionConsumptionByDate.columnTotal, key: 'total' },
  ];

  const tableDailyUsageModel = [
    { label: content.sessionDailyConsumption.columnDateReference, key: 'dateReference' },
    { label: content.sessionDailyConsumption.columnUserSession, key: 'sessionByUser' },
    { label: content.sessionDailyConsumption.columnBusinessSession, key: 'sessionByCompany' },
    { label: content.sessionDailyConsumption.columnTotal, key: 'sessionTotal' },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const monthString = `${dateToSearch.getFullYear()}-${getMonthTwoDigits(dateToSearch)}`;
        const showSessionNewPricing = dateToSearch >= WHATSAPP_BP_PRICING_CHANGES_START_DATE;
        setShowSessionNewPricing(showSessionNewPricing);

        if (showSessionNewPricing) {
          const sessionConsumptionDailyUsage = await billingSessionConsumptionApi.getDailySessionConsumption(
            tenant.info.id,
            monthString,
            registersToTake,
            conversationWabaFilter,
          );
          const businessInitiatedDailyUsage = sessionConsumptionDailyUsage.map((obj) => ({
            dateReference: toUTCDateString(obj.dateReference, content.locale),
            utility: obj.businessInitiated.utility,
            authentication: obj.businessInitiated.authentication,
            marketing: obj.businessInitiated.marketing,
            total: obj.businessInitiated.total,
          }));

          const userInitiatedDailyUsage = sessionConsumptionDailyUsage.map((obj) => ({
            dateReference: toUTCDateString(obj.dateReference, content.locale),
            service: obj.userInitiated.service,
            free: obj.userInitiated.free,
            total: obj.userInitiated.total,
          }));

          setBusinessInitiatedDailyUsage(businessInitiatedDailyUsage);
          setUserInitiatedDailyUsage(userInitiatedDailyUsage);
        } else {
          const sessionConsumptionDailyUsage = await billingSessionConsumptionApi.getOldDailySessionConsumption(
            tenant.info.id,
            monthString,
            registersToTake,
            conversationTypeFilter,
            conversationWabaFilter,
            '',
          );

          const sessionConsumptionDailyUsageParse = sessionConsumptionDailyUsage.map((obj) => ({
            ...obj,
            dateReference: toUTCDateString(obj.dateReference, content.locale),
          }));

          setOldSessionConsumptionDailyUsage(sessionConsumptionDailyUsageParse);
        }
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [dateToSearch, conversationTypeFilter, conversationWabaFilter]);

  const sortBusinessInitiatedDailyUsage = (sort) => {
    const sortedBusinessInitiatedDailyUsage = sortByProperty(businessInitiatedDailyUsage, sort.property, sort.order);
    setBusinessInitiatedDailyUsage([...sortedBusinessInitiatedDailyUsage]);
  };

  const sortUserInitiatedDailyUsage = (sort) => {
    const sortedUserInitiatedDailyUsage = sortByProperty(userInitiatedDailyUsage, sort.property, sort.order);
    setUserInitiatedDailyUsage([...sortedUserInitiatedDailyUsage]);
  };

  const trackDailyButton = () => {
    trackEvent(REPORT_SESSION_CONSUMPTION_PER_DATE_OPEN, tenant, loggedUser, {
      ...trackingProps,
      origin: origin.SESSION,
      action: action.CLICK,
    });
  };

  const periodOptionChangeEvent = async (selectedOption) => {
    const dateToRetrieveData = new Date(selectedOption.detail.value);
    setDateToSearch(dateToRetrieveData);
    const planStatus = await getPlanStatus();
    trackEvent(REPORT_PERIOD_SELECT, tenant, loggedUser, {
      ...trackingProps,
      date: dateToRetrieveData,
      planStatus: planStatus,
      origin: origin.SESSIONPERDATE,
    });
  };

  const wabaOptionChangeEvent = (selectedOption) => {
    const conversationWabaValue = selectedOption.detail.value;
    setConversationWabaFilter(conversationWabaValue);
    trackEvent(REPORT_SESSION_CONSUMPTION_WABAID_SELECT, tenant, loggedUser, {
      ...trackingProps,
      whatsappConversationWaba: conversationWabaValue,
      origin: origin.SESSIONPERDATE,
    });
  };

  const typeOptionChangeEvent = (selectedOption) => {
    const conversationTypeValue = selectedOption.detail.value;
    setConversationTypeFilter(conversationTypeValue);
    trackEvent(REPORT_SESSION_CONSUMPTION_CONVERSATION_SELECT, tenant, loggedUser, {
      ...trackingProps,
      whatsappConversationType: conversationTypeValue,
      origin: origin.SESSIONPERDATE,
    });
  };

  return (
    <>
      {isDetailedView && (
        <PageHeaderComponent
          title={content.sessionDailyConsumption.title}
          dateToSearch={dateToSearch}
          periodOptions={periodOptions}
          periodOptionChangeEvent={periodOptionChangeEvent}
        />
      )}
      <SessionConsumptionPaper>
        <SessionConsumptionRow isDetailedView={isDetailedView}>
          <PaperHeaderComponent
            title={content.sessionDailyConsumption.title}
            subtitle={content.sessionDailyConsumption.about}
            path={'/session-consumption/daily'}
            buttonCaption={content.sessionDailyConsumption.buttonAllChatbots}
            trackButton={trackDailyButton}
            isDetailedView={isDetailedView}
            idTest={'show-all-dash-session-daily-consumption'}
          />
          {isDetailedView && (
            <SessionConsumptionHeaderFilter>
              {conversationWabaFilterOptions.length > 0 && (
                <BdsSelect
                  disabled={conversationWabaFilterOptions.length === 1}
                  value={conversationWabaFilter}
                  label={content.conversationWabaLabel}
                  onBdsChange={wabaOptionChangeEvent}
                >
                  {conversationWabaFilterOptions}
                </BdsSelect>
              )}
              {!showSessionNewPricing && (
                <BdsSelect
                  value={conversationTypeFilter}
                  label={content.conversationTypeLabel}
                  onBdsChange={typeOptionChangeEvent}
                >
                  {conversationTypeFilterOptions}
                </BdsSelect>
              )}
            </SessionConsumptionHeaderFilter>
          )}
        </SessionConsumptionRow>
        <SessionConsumptionUsageStatementContent>
          {showSessionNewPricing ? (
            <bds-tab-group
              align="left"
              onClick={(e) => {
                try {
                  const isActiveFirstTab = e.target.firstChild.getAttribute('open') === '';
                  if (isActiveFirstTab) {
                    trackEvent(REPORT_SESSION_CONSUMPTION_PER_DATE_USERINITIATED_TAB_CLICK, tenant, loggedUser, {
                      ...trackingProps,
                      action: action.CLICK,
                      origin: origin.SESSIONPERDATE,
                    });
                  } else {
                    trackEvent(REPORT_SESSION_CONSUMPTION_PER_DATE_BUSINESSINITIATED_TAB_CLICK, tenant, loggedUser, {
                      ...trackingProps,
                      action: action.CLICK,
                      origin: origin.SESSIONPERDATE,
                    });
                  }
                } catch (error) {}
              }}
            >
              <bds-tab-item label={content.sessionConsumptionByDate.businessInitiated.tab}>
                <BdsBanner variant="system" context="inside">
                  {content.sessionConsumptionByPhone.businessInitiated.bannerText}
                  <Link
                    onClick={() =>
                      trackEvent(REPORT_SESSION_CONSUMPTION_PER_DATE_MOREINFO_CLICK, tenant, loggedUser, {
                        ...trackingProps,
                        action: action.CLICK,
                        origin: origin.SESSIONPERDATE,
                      })
                    }
                    target="_blank"
                    to={{ pathname: WHATSAPP_BP_PRICING_CHANGES_URL }}
                  >
                    {content.sessionConsumptionByPhone.businessInitiated.bannerLinkText}
                  </Link>
                </BdsBanner>
                <BlipTable
                  idKey="businessInitiatedDailySessionConsumption"
                  model={businessInitiatedTable}
                  data={businessInitiatedDailyUsage}
                  selectedItems={[]}
                  onSortSet={sortBusinessInitiatedDailyUsage}
                  canSelect={false}
                  bodyHeight={tableBodyHeight}
                  loading={isWabaloading || isLoading}
                ></BlipTable>
              </bds-tab-item>
              <bds-tab-item label={content.sessionConsumptionByDate.userInitiated.tab}>
                <BdsBanner variant="system" context="inside">
                  {content.sessionConsumptionByPhone.userInitiated.bannerText}
                  <Link
                    onClick={() =>
                      trackEvent(REPORT_SESSION_CONSUMPTION_PER_DATE_MOREINFO_CLICK, tenant, loggedUser, {
                        ...trackingProps,
                        action: action.CLICK,
                        origin: origin.SESSIONPERDATE,
                      })
                    }
                    target="_blank"
                    to={{ pathname: WHATSAPP_BP_PRICING_CHANGES_URL }}
                  >
                    {content.sessionConsumptionByPhone.userInitiated.bannerLinkText}
                  </Link>
                </BdsBanner>
                <BlipTable
                  idKey="userInitiatedDailySessionConsumption"
                  model={userInitiatedTable}
                  data={userInitiatedDailyUsage}
                  selectedItems={[]}
                  onSortSet={sortUserInitiatedDailyUsage}
                  canSelect={false}
                  bodyHeight={tableBodyHeight}
                  loading={isWabaloading || isLoading}
                ></BlipTable>
              </bds-tab-item>
            </bds-tab-group>
          ) : (
            <BdsPaper>
              <BlipTable
                idKey="sessionDailyConsumptions"
                model={tableDailyUsageModel}
                data={oldSessionConsumptionDailyUsage}
                selectedItems={[]}
                onSortSet={sortSessionConsumptionDailyUsage}
                canSelect={false}
                bodyHeight={tableBodyHeight}
                loading={isWabaloading || isLoading}
              ></BlipTable>
            </BdsPaper>
          )}
        </SessionConsumptionUsageStatementContent>
      </SessionConsumptionPaper>
    </>
  );
};

export default SessionConsumptionDailyUsageComponent;
