import { ConsolidatedMiuConsumption } from '../../models/billing/miuConsumption/ConsolidatedMiuConsumption';
import { BlipApi } from '../BlipApi';
import { METHOD, RequestStatus } from 'shared/Requests';
import { BillingMetrics } from 'shared/BillingMetrics';
import { MonthlyMiuConsumption } from 'models/billing/miuConsumption/MonthlyMiuConsumption';
import { DailyMiuConsumption } from 'models/billing/miuConsumption/DailyMiuConsumption';

export default class BillingMiuConsumptionApi {
  private readonly BILLING_COMMAND_DESTINATION = 'postmaster@billing.blip.ai';
  private code = '';
  private status = '';

  public getStatus() {
    return this.status;
  }

  public getCode() {
    return this.code;
  }

  public setSuccess() {
    this.status = RequestStatus.SUCCESS;
  }

  public setErrorLog(e) {
    const error = JSON.parse(<string>e);
    const {
      status,
      reason: { code },
    } = JSON.parse(error.message);
    this.code = code;
    this.status = status;
    console.log(e);
  }

  public async getConsolidatedMiuConsumption(
    tenantId: string,
    metricIds: string,
    month: string,
  ): Promise<Array<ConsolidatedMiuConsumption>> {
    const uri = `/tenants/${tenantId}/monthly-message-metrics/summary?month=${month}&metricIdList=${metricIds}`;
    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return items.map((item) => new ConsolidatedMiuConsumption(item, item.metric.metricId));
    } catch (e) {
      this.setErrorLog(e);
      return new Array<ConsolidatedMiuConsumption>(
        new ConsolidatedMiuConsumption(null, BillingMetrics.monthlyActiveUser),
        new ConsolidatedMiuConsumption(null, BillingMetrics.exchangedMessage),
      );
    }
  }

  public async getMonthlyMiuConsumption(
    tenantId: string,
    metricIds: string,
    month: string,
    registersToTake: number,
  ): Promise<MonthlyMiuConsumption[]> {
    let uri = `/tenants/${tenantId}/monthly-message-metrics/monthly/by-bot?month=${month}&metricIdList=${metricIds}`;

    uri = uri + `&$take=${registersToTake}`;

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return items.map((item) => new MonthlyMiuConsumption(item));
    } catch (e) {
      this.setErrorLog(e);
      return new Array<MonthlyMiuConsumption>();
    }
  }

  public async getDailyMiuConsumption(
    tenantId: string,
    metricIds: string,
    month: string,
    registersToTake: number,
  ): Promise<DailyMiuConsumption[]> {
    let uri = `/tenants/${tenantId}/monthly-message-metrics/daily?month=${month}&metricIdList=${metricIds}`;

    uri = uri + `&$take=${registersToTake}`;

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return items.map((item) => new DailyMiuConsumption(item));
    } catch (e) {
      this.setErrorLog(e);
      return new Array<DailyMiuConsumption>();
    }
  }
}
