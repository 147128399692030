import { WabaConversationConsumption } from 'models/sessionConsumption/WabaConversationConsumption';
import { METHOD, RequestStatus } from 'shared/Requests';
import { ConsolidatedSessionConsumption } from '../models/sessionConsumption/ConsolidatedSessionConsumption';
import { DailySessionConsumption } from '../models/sessionConsumption/DailySessionConsumption';
import { MonthlySessionConsumption } from '../models/sessionConsumption/MonthlySessionConsumption';
import { BlipApi } from './BlipApi';

export default class BillingSessionConsumptionApi {
  private readonly BILLING_COMMAND_DESTINATION = 'postmaster@billing.blip.ai';
  private code = '';
  private status = '';

  public getStatus() {
    return this.status;
  }

  public getCode() {
    return this.code;
  }

  public setSuccess() {
    this.status = RequestStatus.SUCCESS;
  }

  public setErrorLog(e) {
    const error = JSON.parse(<string>e);
    const {
      status,
      reason: { code },
    } = JSON.parse(error.message);
    this.code = code;
    this.status = status;
    console.log(e);
  }

  public async getWabaConversationConsumption(tenantId: string): Promise<WabaConversationConsumption[]> {
    const uri = `/tenants/${tenantId}/wabas`;

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };
    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return items;
    } catch (e) {
      this.setErrorLog(e);
      return new Array<WabaConversationConsumption>();
    }
  }
  public async getConsolidatedSessionConsumption(
    tenantId: string,
    month: string,
    conversationType: string,
    wabaId: string,
  ): Promise<ConsolidatedSessionConsumption> {
    const uri = `/tenants/${tenantId}/whatsapp-sessions/${conversationType}/${wabaId}/total?month=${month}`;

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: METHOD.GET,
      uri: uri,
    };
    if (!wabaId) {
      return new ConsolidatedSessionConsumption(null);
    }

    try {
      const { response } = await BlipApi.sendCommand(command);
      this.setSuccess();
      return new ConsolidatedSessionConsumption(response);
    } catch (e) {
      this.setErrorLog(e);
      return new ConsolidatedSessionConsumption(null);
    }
  }

  public async getMonthlySessionConsumption(
    tenantId: string,
    month: string,
    registersToTake: number,
    conversationType: string,
    wabaId: string,
  ): Promise<MonthlySessionConsumption[] | null> {
    let uri = `/tenants/${tenantId}/whatsapp-sessions/${conversationType}/${wabaId}/monthly/by-bot?month=${month}`;

    if (registersToTake > 0) {
      uri = uri + `&$take=${registersToTake}`;
    }

    if (!wabaId) {
      return new Array<MonthlySessionConsumption>();
    }

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: 'get',
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      return items.map((item) => new MonthlySessionConsumption(item));
    } catch (e) {
      return new Array<MonthlySessionConsumption>();
    }
  }

  public async getDailySessionConsumption(
    tenantId: string,
    month: string,
    registersToTake: number,
    conversationType: string,
    wabaId: string,
  ): Promise<DailySessionConsumption[] | null> {
    let uri = `/tenants/${tenantId}/whatsapp-sessions/${conversationType}/${wabaId}/daily?month=${month}`;

    if (registersToTake > 0) {
      uri = uri + `&$take=${registersToTake}`;
    }

    if (!wabaId) {
      return new Array<DailySessionConsumption>();
    }

    const command = {
      to: this.BILLING_COMMAND_DESTINATION,
      method: 'get',
      uri: uri,
    };

    try {
      const {
        response: { items },
      } = await BlipApi.sendCommand(command);
      return items.map((item) => new DailySessionConsumption(item));
    } catch (e) {
      return new Array<DailySessionConsumption>();
    }
  }
}
